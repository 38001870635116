import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	requestTesterDetails, 
	requestGaugesList, updateTester
} from "../../../actions/employee.js"

import {format} from "date-fns"

export default function TesterDetails(){
	const testerID = useSelector((state) => state.employee_management_testerID); 
	const details = (useSelector ( (state) => state.employee_management_details) )
	const gauges = useSelector( (state) => state.employee_management_gauges)
	
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [showTester, setShowTester] = useState(false)

	const [name, setName] = useState(null); 
	const [WA, setWA] =useState(null); 
	const [OR, setOR] = useState(null); 
	const [gauge, setGauge] = useState(null); 
	const [signature, setSignature] = useState(null); 
	const [link, setLink] = useState(null) 
	const [newInstallLink, setNewInstallLink] = useState(null)

	useEffect(() =>{
		if(testerID == -1){
			setShowTester(false)
		}else{
			setShowTester(true)
		}
	}, [testerID])


	useEffect(()=>{
		if(details != null){
			setName(details.tester_name); 
			setWA(details.WA_cert); 
			setOR(details.OR_cert); 
			setGauge(details.gaugeID); 
			setSignature(details.signature)
			setLink(details.link)
			setNewInstallLink(details.newInstallLink)
		}
	}, [details])
	
	


	const[gaugeLoader, setGaugeLoader] = useState(false)

	useEffect(()=>{
		setGaugeLoader(false)
		dispatch( requestGaugesList () ).then((data, err) =>{
			setGaugeLoader(true)

		})
	}, [])

	const timeZoned = (date) =>{
		let timed = date.slice(0,10)
		let actualString = `${timed}T00:00:00`
		console.log("actual date: ", actualString)
		return actualString
	}

	const update = () => {
		if (testerID != null && testerID != -1 ) {
			let obj = {}
			obj.name = name;
			obj.OR_cert = OR;
			obj.WA_cert = WA;
			obj.gauge = gauge;
			obj.signature = signature;

			dispatch(updateTester(testerID, obj)).then((data, err) => {
				console.log("Updated tester information")
				setLoader(false)
				dispatch(requestTesterDetails(testerID)).then((data, err) => {
					setLoader(true)
				})
			})
		}
	}


	const renderGauges = gauges.map((gg) =>{
		let strDate = ''
		if(gg.calibrationDate){
			strDate = timeZoned(gg.calibrationDate)
			return(
				<option value ={gg.id}> {gg.serialN}  - {gg.model} - {format( new Date( strDate ), "MM/dd/yyyy" )}</option>
			)
		}else{
			return(<> </>)
		}
		
	})


	return(
		<>
			
				<Container>

					{
						showTester ?
							<>
								<Row>
									<Col>
										<FloatingLabel label = "Name">
											<Form.Control 
												value={name}
												onChange = { (event) => setName(event.target.value)}
												onBlur = {update}
											/>
										</FloatingLabel>
										<FloatingLabel label = "Oregon Certification">
											<Form.Control 
												value={OR}
												onChange = { (event) => setOR(event.target.value)}
												onBlur = {update}
											/>
										</FloatingLabel>
										<FloatingLabel label = "Washington Certification">
											<Form.Control 
											value= {WA}
											onChange = { (event) => setWA(event.target.value)}
												onBlur = {update}
											/>
										</FloatingLabel>
										<FloatingLabel label = "Gauge">
											<Form.Select 
												value = {gauge}
												onChange={
													(event) => {
														setGauge(event.target.value)
													}
												}
												onBlur = { update }
											>	
												{renderGauges}
											</Form.Select>
										</FloatingLabel>
										<FloatingLabel label = "Signature File">
											<Form.Control 
												value= {signature}
												onChange = { (event) => setSignature(event.target.value)}
												onBlur = {update}
											/>
										</FloatingLabel>
										<Row>
											<Col>
												<Button variant = "link">
													<a href = {link} target= "_blank">
													Certificates
													</a>
												</Button>
											</Col>
											<Col>
												<Button variant = "link">
													<a href = {newInstallLink} target= "_blank">
														New Install Report
													</a>
												</Button>
											</Col>
										</Row>
									</Col>
									
								</Row>
								
							</>
						:
							<>
								Nothing to show...
							</>
					}
								
				</Container>	

		</>
			
	)
}