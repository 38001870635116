import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {format} from "date-fns"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {requestFailedTestReports, setID, requestTestReport} from "../../../../actions/failed" 
import {determineReportID} from "../../../../actions/waterDelivery"
import { default   as TT } from "../TestReport"

export default function TestReport(){
	const currentID = useSelector((state) => state.failed_id)
	const [showReport, setShowReport] = useState(false)
	const dispatch = useDispatch(); 
	const myReport = useSelector((state) => state.failed_report)

	useEffect(()=>{
		if(currentID != null){
			setShowReport(false)
			dispatch( requestTestReport(currentID) ).then( (data, err) => {
				console.log("Received test report object")
				setShowReport(true)
			})
		}

		setShowReport(false)
		if(currentID != null){
			dispatch( determineReportID(currentID ) ).then((data, err) => {
				setShowReport(true)
				
			})

		}
	},[currentID])

	return(


		<Container>
			{
				showReport ?
					<>
						<Row>
							<Col>
								<TT />
							</Col>
						</Row>
						
				</>
			: 
				<> Loading... </>

			}
				
		</Container>
	)
}