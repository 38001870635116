import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container'
import { format, add} from "date-fns"
import {
	updateServiceAddress, 
	queryTerms, 
	getTermID
} from "../../../actions/invoice"


export default function ServiceAddress(){
	const address= useSelector( (state) =>state.invoice_service_address)
	const termList = useSelector((state) => state.invoice_term_list)
	const invoice = useSelector( (state) => state.invoice)
	const invoiceID = useSelector( (state)=> state.invoiceID)
	const [termID, setTermID] = useState(null)
	const [termIndex, setTermIndex] = useState(-1)
	const [name, setName] = useState("")
	const [street, setStreet] = useState("")
	const [city, setCity] = useState("")
	const [state, setState] = useState("")
	const [zipcode, setZipcode] = useState("")
	const [salesTax, setSalesTax] = useState(null)
	const [dueDate, setDueDate] = useState(format( new Date(), "MM/dd/yyyy") ) 
	const [termsLoaded, setTermsLoaded] = useState(false)
	const [dueDateDays, setDueDateDays] = useState(15)
	const dispatch = useDispatch(); 

	/*useEffect(()=>{
		if(termsLoaded && termIndex >= 0){
			setTermID( termList[termIndex].Id)
			setDueDateDays( termList[termIndex].DueDays)
			dispatch( getTermID( termList[termIndex].Id) ).then((data, err) =>{
				console.log("Updated Term Id!; ")

			})
		}
	}, [termIndex])*/

	useEffect(()=>{
		setTermsLoaded(false)
		dispatch( queryTerms() ).then((data, err) =>{
			console.log("Got the terms!")
			
			setTermsLoaded(true)
			setTermIndex(0)
		})
	}, [])

	useEffect(()=>{
		setStreet(address.street)
		setCity(address.city)
		setState(address.state)
		setZipcode(address.zipcode)
		setName(address.locationName)
		setSalesTax(address.sales_tax)
	}, [])

	useEffect( ()=>{
		let myAddedDate = add( new Date(invoice.service_date), {
			year: 0, 
			months:0, 
			weeks: 0, 
			days: dueDateDays, 
			hours: 0, 
			minutes: 0, 
			seconds: 0 
		})
		setDueDate(format(myAddedDate, "MM/dd/yyyy"))

	}, [dueDateDays])

	const renderTerms = termList.map( (term, index) => {
		return(
			<option value= {index}>
				{term.Name}
			</option>
		)
	})

	return(
		<Container>
		
			<Row>
				<Col>
					<strong>Service Address</strong>
					<FloatingLabel label = "Name">
					<Form.Control
						value={name}
						size = "sm"
						placeholder = "Name"
						onChange = {(event)=> setName(event.target.value)}
						onBlur = {() =>{
							if( name != address.locationName){
								dispatch ( 
									updateServiceAddress({
										locationName: name, 
										street: street, 
										city: city, 
										state: state, 
										zipcode: zipcode,
										sales_tax: salesTax},
										address.addressID,
										invoiceID ))
								.then((data, err) =>{
									console.log("updated billing name")
								})
							}
						}}
					/>
				</FloatingLabel>
				<FloatingLabel label = "Street"> 
				<Form.Control 
					value={street}
					placeholder="Street"
					size = "sm"
					onChange = {(event)=> setStreet(event.target.value)}
					onBlur = {() =>{
						if( street != address.street){
							dispatch ( 
								updateServiceAddress({
									locationName: name, 
									street: street, 
									city: city, 
									state: state, 
									zipcode: zipcode,
									sales_tax: salesTax},
									address.addressID,
									invoiceID ))
							.then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label = "City">
				<Form.Control 
					value={city}
					placeholder="City"
					size = "sm"
					onChange = {(event)=> setCity(event.target.value)}
					onBlur = {() =>{
						if( city != address.city){
							dispatch ( 
								updateServiceAddress({
									locationName: name, 
									street: street, 
									city: city, 
									state: state, 
									zipcode: zipcode,
									sales_tax: salesTax},
									address.addressID,
									invoiceID ))
							.then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label = "State">
				<Form.Control 
					value={state}
					placeholder="State"
					size = "sm"
					onChange = {(event)=> setState(event.target.value)}
					onBlur = {() =>{
						if( state != address.state){
							dispatch ( 
								updateServiceAddress({
									locationName: name, 
									street: street, 
									city: city, 
									state: state, 
									zipcode: zipcode,
									sales_tax: salesTax},
									address.addressID,
									invoiceID ))
							.then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
					/>
				</FloatingLabel>
				<FloatingLabel label = "Zipcode">
				<Form.Control 
					value={zipcode}
					placeholder="Zipcode"
					size = "sm"
					onChange = {(event)=> setZipcode(event.target.value)}
					onBlur = {() =>{
						if( zipcode != address.zipcode){
							dispatch ( 
								updateServiceAddress({
									locationName: name, 
									street: street, 
									city: city, 
									state: state, 
									zipcode: zipcode,
									sales_tax: salesTax

								},
									address.addressID,
									invoiceID ))
							.then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
				/>
				</FloatingLabel>
				</Col>
			</Row>

				<Row>
					<Col>
						Service Date  <br/>
						{
						 format( new Date( invoice.service_date), "MM/dd/yyyy")  
						}
					</Col>
				<Col>
					<FloatingLabel label = "Due Date">
						<Form.Control
							value = 	{format( new Date( dueDate), "MM/dd/yyyy")  }
							readOnly
							size="sm"
							id="duedate"
						/>
					</FloatingLabel>
				</Col>
					<FloatingLabel label = "Sales Tax">
					<Form.Control
						value={salesTax}
						onBlur={() => {
							if (salesTax != address.sales_tax) {
								dispatch(
									updateServiceAddress({
										locationName: name,
										street: street,
										city: city,
										state: state,
										zipcode: zipcode,
										sales_tax: salesTax
									},
										address.addressID,
										invoiceID))
									.then((data, err) => {
										console.log("updated billing name")
									})
							}

						}}
						size="sm"
						id="duedate"
					/>
					</FloatingLabel>
				</Row>
			
				<Row>
				<Col>
					<FloatingLabel label = "Due Days">
						<Form.Select  value={termIndex} onChange= {
								(event) => {
									setTermIndex(event.target.value)
					
								} 
							} size="sm">
							{
								termsLoaded? 
									<> {renderTerms} </>
								: 
									<>
									</>
							}
							
							
						</Form.Select>
					</FloatingLabel>
					</Col>
				</Row>	
		
		</Container>
	)
}