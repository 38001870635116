import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FailedList from "./FailedList"
import AssemblyDetails from "./AssemblyDetails"
import CustomerDetails from "./CustomerDetails"
import TestReport from "./TestReport"
import Actions from "./Actions"
import PartsForm from "./PartsForm"

import {requestFailedTestReports, setID, flagAsScheduled } from "../../../../actions/failed" 


export default function FailedApp(){
	const [disableButton, setDisableButton] = useState(false) 
	const dispatch = useDispatch(); 
	const currentID = useSelector((state) => state.failed_id)
	const [load, setLoad] = useState(false)
	const flagIt = ()=>{
		console.log("Flaggin it..")
		setDisableButton(true)
		 flagAsScheduled(currentID) .then((data, err) =>{
			setDisableButton(false)
			setLoad(true)
			dispatch( requestFailedTestReports() ).then((data, err)=>{
				setLoad(false)
			})
		})
	}
	return(
		<Container fluid className="main-container-scrollable">
			<Row>
				<Col>
					<h4>Failed Reports</h4>
				</Col>
				<Col>
					<FailedList />
				</Col>
			</Row>
			<hr/>
			<Row>
				
				<Col>
					<Container>
						<Row>
							<Col>
								<Button size="sm" variant = "success" disable = {disableButton} onClick ={flagIt}>
									Flag As Scheduled
								</Button>
							</Col>
						</Row>
						<Row>
							<Col >
							{
								load ?
									<Spinner />
								: 
									<TestReport />

							}
							
					
							</Col>
			
						</Row>
					</Container>
				</Col>
			
			</Row>
						
		</Container>
	)
}